footer{
    border:0px solid red;
    background-image: url('../Images/Rectangle 38534.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 30px 10px 30px;
}
.footer-media{
    /*padding-top: 20px;*/
    padding: 20px 0px 40px 0px;
}
.footer-media p{
    font-weight: 400;
    font-size:14px;
    line-height: 26px;
    color: #333333;
    margin: 35px 0px;
}
.footer-icon{
    background-color: #DBE3F9;
    padding: 8px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 15px;
    color: blue;
}
.footer-media .footer-icon:nth-child(2){
    color: black!important;
}
.footer-media .footer-icon:nth-child(3){
    color: #FD0056;
}
.footer-menu ul{
    padding:0px;
    margin-top: 12px;
}
.footer-menu ul li{
    list-style: none;
    font-weight: 600;
    font-size: 15px;
    margin: 18px 0px;
    color: #5F6981;
    text-transform: capitalize;
    cursor: pointer;
}
.footer-menu ul li:hover{
    color: #08C873;
}
.footer-menu h5{
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    text-transform: uppercase;
    color: #333333;
}
.tools img{
    width: 37px;
    height: 37px;
    margin-right: 10px;
}
.footer-menu .link{
    color:#5F6981;
    text-decoration: none;
}
.footer-menu .link:hover{
    color: #08C873;
}
.pstore img{
    margin-top: 18px;
    margin-right: 20px;
}
.footer-menu{
    padding-bottom: 25px;
}
.footer-copy{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
}
.footer-copy .term-link{
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #FFFFFF;
    text-transform: capitalize;
    text-decoration: none;
    padding: 0px 10px;
}
.footer-copy .term-link:nth-child(2){
    border-left: 2px solid #878EA0;
    border-right: 2px solid #878EA1;
}
.footer-copy h6{
    margin:0px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
}
.footer-copy span{
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #e9e6e6;
}
.copyright{
    background: linear-gradient(0deg, #333333, #333333), #E1E9FD;
    padding: 30px 15px;
}
.clr-white{
    color:white;
}

@media screen and (max-width:575px){
    footer {
        padding: 90px 15px 10px 15px;
    }
    .footer-menu ul li {
        margin: 10px 0px;
        font-size: 14px;
    }
}
@media screen and (max-width:998px){
    .footer-copy {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
}

